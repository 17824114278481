import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Deutsch, English, Español } from '../../../Constant';

const Language = () => {
  const [langdropdown, setLangdropdown] = useState(false);
  const [selected, setSelected] = useState(localStorage.getItem('i18nextLng'));
  const { i18n } = useTranslation();

  const browserLocale = navigator.language || navigator.userLanguage; // Get the browser's locale

  useEffect(() => {
    if (browserLocale == 'es-ES') {
      changeLanguage('es')
    }else if(browserLocale == 'de-DE'){
      changeLanguage('du')
    }else{
      changeLanguage('en')
    }
  }, [browserLocale])

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelected(lng);
  };
  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language);
    } else {
      setLangdropdown(!language);
    }
  };

  return (
    <li className='language-nav'>
      <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
        <div className='current_lang'>
          <div className='lang' onClick={() => LanguageSelection(langdropdown)}>
            <i className={`flag-icon flag-icon-${selected === 'en' ? 'us' : selected === 'du' ? 'de' : selected}`}></i>
            <span className='lang-txt'>{selected}</span>
          </div>
        </div>
        {/* <div className={`more_lang ${langdropdown ? 'active' : ''}`}> */}
        <div className={`more_lang ${langdropdown ? 'active' : ''}`} >
          <div className='lang' onClick={() => [setLangdropdown(!langdropdown),changeLanguage('en'),LanguageSelection(langdropdown)]}>
            <i className='flag-icon flag-icon-us'></i>
            <span className='lang-txt'>
              {English}
              <span> {'(US)'}</span>
            </span>
          </div>
          <div className='lang' onClick={() => [setLangdropdown(!langdropdown),changeLanguage('du'),LanguageSelection(langdropdown)]}>
          {/* <div className='lang'> */}
            <i className='flag-icon flag-icon-de'></i>
            <span className='lang-txt'>{Deutsch}</span>
          </div>
          <div className='lang' onClick={() => [setLangdropdown(!langdropdown),changeLanguage('es'),LanguageSelection(langdropdown)]}>
            <i className='flag-icon flag-icon-es'></i>
            <span className='lang-txt'>{Español}</span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Language;
